<template>
  <v-container>
    <div v-if="authorizedAccess">
      <v-layout row wrap justify-space-between class="mx-1 mb-2">
        <v-spacer></v-spacer>
        <v-flex xs12 sm12 md12 lg12 class="text-right">
          <v-btn
            :color="
              button.selected
                ? $store.state.secondaryColor
                : $store.state.primaryColor
            "
            outlined
            class="mx-1 my-1"
            small
            v-for="button in menu"
            :key="button.title"
            @click="goTo(button.route)"
          >
            <v-icon small left>{{ button.icon }}</v-icon>
            <span class="caption text-capitalize">{{ button.title }}</span>
          </v-btn>
        </v-flex>
      </v-layout>

      <router-view></router-view>
    </div>
    <div class="fill-height" v-else>
      <UnauthorizedAccess :user="user" />
    </div>
  </v-container>
</template>

<script>
import jwtdecode from "jwt-decode";
import UnauthorizedAccess from "@/components/mijini/UnauthorizedAccess";
export default {
  components: {
    UnauthorizedAccess,
  },
  data: () => ({}),

  created() {
    this.selectedChange();
    this.getUser();
  },
  computed: {
    menu() {
      return [
        {
          icon: "mdi-file-document-multiple",
          title: this.$t("sales reports"),
          route: "SalesReports",
          path: "",
          selected: false,
        },
        {
          icon: "mdi-file-document-multiple",
          title: this.$t("stock balance report"),
          route: "StockBalance",
          path: "stock-balance",
          selected: false,
        },
        {
          icon: "mdi-file-document-multiple",
          title: this.$t("P & L statements"),
          route: "IncomeStatement",
          path: "income-statement",
          selected: false,
        },
      ];
    },
  },

  watch: {
    $route() {
      this.selectedChange();
    },
  },

  methods: {
    getUser() {
      this.user = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      );

      if (!this.user.accessLevel.isEmployee) this.authorizedAccess = true;
      else this.authorizedAccess = false;
    },
    goTo(route) {
      this.$router.push({ name: route });
    },
    selectedChange() {
      this.menu.forEach((val) => {
        val.selected = false;
      });
      var route = this.$route.path;

      var submenu1 = [
        "SalesReports",
        "MonthlySalesReport",
        "QuarterlySalesReport",
        "AnnualSalesReport",
      ];

      if (submenu1.includes(this.$route.name)) {
        this.menu[0].selected = true;
      } else {
        var i;
        var lang = localStorage.getItem("lang");
        //var lang = "en";
        if (lang == null) {
          i = this.menu.findIndex((x) => "/en/reports/" + x.path == route);
        } else {
          i = this.menu.findIndex(
            (x) => "/" + lang + "/reports/" + x.path == route
          );
        }
        if (i > 0) this.menu[i].selected = true;
      }
    },
  },
};
</script>

<style></style>
